let variables = {
  mail: {
    link: "mailto:starinkalanya@gmail.com",
  },
  linkedin: {
    link: "",
  },
  instagram: {
    link: "https://www.instagram.com/starinkalanya/",
  },
  behance: {
    link: "",
  },
  whatsapp: {
    link: "https://api.whatsapp.com/send?phone=905314557704",
  },
  tel: {
    link: "tel:+905314557704",
  },
  facebook: {
    link:"https://www.facebook.com/starinkalanya?",
  },
  fmessenger: {
    link:"http://m.me/starinkalanya?hash=(null)&source_id=6946816",
  },
  gmaps: {
    link: "https://short.ekartvizzit.com/starinklokasyon",
  },
  greviews: {
    link: "https://short.ekartvizzit.com/starinkreview",
  },
  user: {
    name: "Star Ink Alanya",
    occupation: "Tattoo & Piercing",
    website: "starinkalanya.com",
  },
};

export default variables;
