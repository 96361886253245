import CTA from "./CTA";
import profileImg from "../images/profile-img.jpg";
import variables from "./config";

function Profile() {
  return (
    <div className="profile">
      <div className="profile-img">
        <img src={profileImg} alt="profile-img" />
      </div>
      <div className="profile-info">
        <p className="profile-name">{variables.user.name}</p>
        <p className="profile-occupation">{variables.user.occupation}</p>
        <p className="profile-website">{variables.user.website}</p>
      </div>

      <CTA />
    </div>
  );
}
export default Profile;
