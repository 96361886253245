function Footer() {
  return (
    <div className="footer">
      <p className="footer-text">
        Made by{" "}
        <a id="ekartvizzit" href="https://ekartvizzit.com">
          e-Kartvizzit{" "}
        </a>
        with{" "} ❤️
      </p>
    </div>
  );
}
export default Footer;
