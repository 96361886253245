import Profile from "./Profile";
import About from "./About";
import QR from "./QR";
import Footer from "./Footer";
function Card() {
  return (
    <div className="card">
      <Profile />
      <About />
      <QR />
      <Footer />
    </div>
  );
}
export default Card;
