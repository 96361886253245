import qr from "../images/qr.png";

function QR() {
  return (
    <div className="qr">
      <img src={qr} alt="QR code" />
      <p className="qr-text">
        You can scan this QR code to visit this website.
      </p>
    </div>
  );
}
export default QR;
